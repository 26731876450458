<template>
	<div class="sa-otp-verify">
		<h5 class="sa-mb10">Enter your code !</h5>
		Please enter your code you have received in your registered email id
        <!-- <ValidationObserver> -->
          <div class="sa-form-group">
            <div class="sa-row">
              <div class="sa-col2">
                <input ref="digit_0" v-model="digit_0" type="tel" class="sa-form-control sa-line-control sa-text-center"
                  @input="focusInput('0')" />
              </div>
              <div class="sa-col2">
                <input ref="digit_1" v-model="digit_1" type="tel" class="sa-form-control sa-line-control sa-text-center"
                  @input="focusInput('1')" @keyup.delete="$refs.digit_0.focus()" />
              </div>
              <div class="sa-col2">
                <input ref="digit_2" v-model="digit_2"  type="tel" class="sa-form-control sa-line-control sa-text-center"
                  @input="focusInput('2')" @keyup.delete="$refs.digit_1.focus()" />
              </div>
              <div class="sa-col2">
                <input ref="digit_3" v-model="digit_3" type="tel" class="sa-form-control sa-line-control sa-text-center"
                @input="focusInput('3')"  @keyup.delete="$refs.digit_2.focus()" />
              </div>
              <div class="sa-col2">
                <input ref="digit_4" v-model="digit_4" type="tel" class="sa-form-control sa-line-control sa-text-center"
                  @input="focusInput('4')" @keyup.delete="$refs.digit_3.focus()" />
              </div>
              <div class="sa-col2">
                <input ref="digit_5" v-model="digit_5" type="tel" maxlength="1" class="sa-form-control sa-line-control sa-text-center"
                 @keyup.delete="$refs.digit_4.focus()"  />
              </div>
            </div>
            </div>
            <div class="sa-form-group sa-black-text sa-text-right">
                <!-- <span class="sa-blue-text sa-text-underline sa-f-size12" @click=" $emit('close-pop')">{{$t('change_number')}}</span> -->
                <!-- <button
             type="button"
              class="sa-text-underline sa-blue-text sa-btn-transparent"
              @click="resendOtp">
              {{ $t("resend_otp") }}
            </button> -->
            <!-- <span>{{ $t("You will get OTP in") }} - <span class="sa-blue-text">5:00</span></span> -->
          </div>
          <div class="sa-btn sa-btn-primary sa-btn-block sa-mt40" @click="verifyOTP">{{ $t("verify") }}</div>
        <!-- </ValidationObserver> -->
    </div>
</template>
<script>
export default {
  data() {
    return {
      digit_0: "",
      digit_1: "",
      digit_2: "",
      digit_3: "",
      digit_4: "",
      digit_5: "",
    };
  },
  methods: {
    async verifyOTP() {
      this.$store.dispatch("common/SET_LOADING", true);
      if (this.otpCode && this.otpCode.length == 6) {
        // eslint-disable-next-line no-unused-vars
        const { data } = await this.$store.dispatch("auth/DO_VERIFY",this.otpCode);
        if (data && data.token) {
          this.$store.dispatch("common/SET_LOADING", false)
          this.$store.dispatch("common/SET_ALERTMESSAGE", {
            iconClass: "icon-sun",
            msg: data.message,
            class: "sa-a-success",
          });
          this.$router.push("/appointment-details");
        } else {
          this.$store.dispatch("common/SET_ALERTMESSAGE", {
            iconClass: "icon-info",
            msg: data.message,
            class: "sa-a-danger",
          });
        }
      } else {
        this.$store.dispatch("common/SET_ALERTMESSAGE", {
            iconClass: "icon-info",
            msg: "Please provide the code",
            class: "sa-a-danger",
          });
      }this.$store.dispatch("common/SET_LOADING", false)
    },
    focusInput(fd) {
      if( this["digit_" + fd].length > 1 ){
        let val = this["digit_" + fd].charAt(1)
        this["digit_" + fd] = this["digit_" + fd].slice(0, 1);
        this["digit_" + (1 + parseInt(fd))] = val
        this.$refs["digit_" + (1 + parseInt(fd))].focus()
      }
      if (this["digit_" + fd] != "" && parseInt(this["digit_" + fd]) > -1) {
        this.$refs["digit_" + (1 + parseInt(fd))].focus()
      } else {
        this["digit_" + fd] = null;
      }
    },
  },
  computed: {
    otpCode() {
      return `${this.digit_0}${this.digit_1}${this.digit_2}${this.digit_3}${this.digit_4}${this.digit_5}`;
    },
  },
};
</script>